import { Chip } from "@material-tailwind/react";
import React from "react";

function TrainingTag({ data }) {
  return (
    <div className="flex mb-2">
      {!data ? (
        <Chip color="indigo" variant="ghost" value="Not Paid" />
      ) : (
        <Chip color="green" variant="ghost" value="Paid" />
      )}
    </div>
  );
}

export default TrainingTag;
