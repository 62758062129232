import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
} from "@material-tailwind/react";
import React from "react";

function Modal({
  title,
  setOpen,
  children,
  handleClose,
  handleConfirm,
  loading,
  size = "md",
}) {
  return (
    <Dialog open={setOpen} size={size}>
      <DialogHeader>{title}</DialogHeader>
      <DialogBody>{children}</DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={handleClose}
          className="mr-1"
        >
          <span>Close</span>
        </Button>
        <Button
          disabled={loading}
          onClick={handleConfirm}
          className="flex items-center gap-3"
        >
          {loading && <Spinner className="h-4 w-4" />}
          <span>Confirm</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default Modal;
