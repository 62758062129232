import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import React from "react";

function ConfirmPayment({
  open,
  setOpen,
  title,
  amount,
  loading,
  handleConfirm,
  handleClose,
}) {
  const totalAmount = (
    parseFloat(amount) +
    parseFloat(amount) * (title === "Electronic" ? 0.02 : 0.03)
  ).toLocaleString();

  return (
    <Dialog open={open} size="md" handler={setOpen}>
      <DialogHeader>Confirm {title} Payment</DialogHeader>
      <DialogBody>
        <Typography color="black" variant="lead">
          Total amount to be paid:{" "}
          <strong>
            {amount} + {title === "Electronic" ? `2%` : `3%`} charge = GH¢
            {totalAmount}
          </strong>{" "}
        </Typography>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={handleClose}
          className="mr-1"
        >
          Close
        </Button>

        <Button
          disabled={loading}
          onClick={handleConfirm}
          className="flex items-center gap-3"
        >
          {loading && <Spinner className="h-4 w-4" />}
          Confirm
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default ConfirmPayment;
