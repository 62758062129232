import React from "react";
import SectionTitle from "../../common/shared/SectionTitle";
import { Typography } from "@material-tailwind/react";
import logo from "../../assets/img/gba-logo.png";

function Policy() {
  return (
    <>
      <SectionTitle title={"Privacy Policy"} description={""} />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        <div className="text-center">
          <img src={logo} alt="logo" className="h-30 inline-block" />

          <Typography variant="h2" className="my-10">
            GBA Online Software: Privacy Policy
          </Typography>
        </div>

        <Typography>
          This portal, website, social media platform, blog, vlog, online
          data/discussion forum, mobile app (the “Site”) has been designed and
          provided by the Ghana Bar Association (GBA). Use of the Site,
          howsoever your means of access, use, interaction or participation and
          whatsoever your duration of access, use, interaction or participation,
          is governed by our terms and conditions of use (“T&C”) which is
          available on the first page of this Site.
        </Typography>
        <Typography variant="h3" className="mt-10">
          PRIVACY NOTICE
        </Typography>
        <Typography className="my-4">
          On visiting or using the Site we sometimes need to collect information
          on you or about you. We are committed to preserving your privacy. This
          policy attempts to help you understand how we use and protect your
          information obtained from using our Site.
        </Typography>
        <Typography variant="h3" className="mt-10">
          INFORMATION ABOUT YOU
        </Typography>
        <Typography className="my-4">
          We use information collected on you or about you to serve you, our
          members, the larger body of lawyers in Ghana, the judicial service of
          Ghana, users or potential users of the court or judicial system of
          Ghana.
        </Typography>
        <Typography>
          This could be as part of your application process for a license,
          registering for an event or through the general public seeking
          information on or about our members and lawyers in Ghana.
        </Typography>
        <Typography variant="h3" className="mt-10">
          FORMS
        </Typography>
        <Typography className="my-4">
          On each of our online forms you use, there will be further details of
          why we are collecting the information asked for. We will always ask
          for your consent to process your information before you can submit the
          form.
        </Typography>
        <Typography variant="h3" className="mt-10">
          STORING YOUR PREFERENCE
        </Typography>
        <Typography className="my-4">
          We store some preferences you have using the Site with the sole
          purpose of saving you from repeated entry of your information.
        </Typography>
        <Typography variant="h3" className="mt-10">
          WAYS WE CONTACT YOU
        </Typography>
        <Typography className="my-4">
          We collect and store your contact information such as address, phone
          number and e-mail address. Depending on the circumstances we may
          contact you by post, telephone, e-mail or text message by whatever
          technology available to us. Please contact us through the contact
          information below to update these preferences.
        </Typography>
        <Typography variant="h3" className="mt-10">
          STORING YOUR INFORMATION
        </Typography>
        <Typography className="my-4">
          In most cases, your personal data is stored on cloud servers procured
          for the use of the GBA. We employ the services of third-party
          companies to enhance the quality, service and functionality of the
          services available on our Site.
        </Typography>
        <Typography>
          GBA however always retains ownership and control over the personal
          data that you handover to us through this Site. Such ownership and
          control however remains subject to our contract for cloud server
          services.
        </Typography>
        <Typography variant="h3" className="mt-10">
          UPDATE PERSONAL INFORMATION
        </Typography>
        <Typography className="my-4">
          You can request that we update or remove information about you on our
          Site. This could be because of a name change or because you no longer
          wish to be a lawyer. Such changes are however subject to the decision
          of the General Legal Council of Ghana from whom you must seek prior
          approval for us to effect the change on our Site.
        </Typography>
        <Typography variant="h3" className="mt-10">
          WHO DO I NEED TO CONTACT
        </Typography>
        <Typography className="my-4">
          To request an information change or removal you should contact the GBA
          at the address provided below.
        </Typography>
        <Typography variant="h3" className="mt-10">
          HOW WE USE YOUR INFORMATION
        </Typography>
        <Typography className="my-4">
          Your personal details can be used to provide you with information,
          products or services as well as helping us tailor the Site to your
          preferences. We also use your personal details to respond to users or
          potential users of the court system who inquire about lawyers in
          Ghana. We however do not provide information on date of birth that may
          be linked to your person.
        </Typography>
        <Typography>We use your information to:</Typography>
        <ul className="list-disc ml-10">
          <li>
            maintain a contract we have with you, such as your solicitor license
            renewal or any other service you wish to access
          </li>
          <li>
            enable us to supply you with the goods, services and information you
            have requested through our Site
          </li>
          <li>
            ensure that content from the Site is presented in the most effective
            manner for you and for your access device or computer
          </li>
          <li>
            in order to administer, support, improve and develop the Site we do
            analyse some information we collect during your visit to our Site.
          </li>
          <li>notify you about changes to our service</li>
          <li>
            notify you about our activities and provide you with general
            information that concern our members or lawyers in Ghana
          </li>
          <li>
            Publish membership directories, membership lists and registration
            lists on our Site and in print and digital media to allow lawyers
            and the public to find you; such directories and lists will not
            include credit card information, date of birth or personal
            identification numbers such as social security numbers and tax
            identification numbers.
          </li>
        </ul>
        <Typography variant="h3" className="mt-10">
          COOKIES
        </Typography>
        <Typography className="my-4">
          We use cookies and other technologies to improve our service, enable
          the use of third-party services like YouTube videos for continuous
          legal education (CLE), and serve interest-based advertising on
          third-party websites. You may clear cookies data that we have stored
          on you, or change your cookie settings through the web browser that
          you use to access our Site.
        </Typography>
        <Typography variant="h3" className="mt-10">
          WHAT IS A COOKIE?
        </Typography>
        <Typography className="my-4">
          A cookie is a small file downloaded on to your device when you access
          certain websites. Cookies allow sites to recognise your device when
          you make a return visit.
        </Typography>
        <Typography>
          We use several types of cookies for a range of reasons.
        </Typography>
        <Typography className="my-4">
          Some are essential, while others you can opt out of or block. Opting
          out or blocking some cookies may affect the functionality of the Site.
        </Typography>
        <Typography>
          When you leave our Site or click on links that lead to external
          websites, we are unable to control what cookies are set in any way.
          You will need to set your cookie preferences for those sites as well.
        </Typography>
        <Typography variant="h3" className="mt-10">
          WHY AND HOW WE USE COOKIES
        </Typography>
        <Typography className="my-4">
          There are broadly four reasons why a cookie might be stored on your
          device.
        </Typography>
        <ul className="list-disc ml-10">
          <li>
            Cookies that make the Site work properly for you and enable you to
            make use of the secure online services that we provide.
          </li>
          <li>
            Cookies that collect data about your use of the Site which is then
            anonymised and used to help us improve our online services.
          </li>
          <li>
            Cookies that remember your preferences and make the site easier for
            you to use.
          </li>
          <li>
            Cookies that are placed by third party services we make use of to
            enhance the information we present online. We have no control over
            these third party cookies.
          </li>
        </ul>
        <Typography className="my-4">
          These cookies are placed on your device either by us or by the third
          parties whose services we use as part of the Site.
        </Typography>
        <Typography>
          Some cookies are retained in your browser for only as long as you
          visit our Site, while others persist for a longer specified or
          unspecified period.
        </Typography>
        <Typography variant="h3" className="mt-10">
          HOW TO BLOCK COOKIES
        </Typography>
        <Typography className="my-4">
          You can restrict or block cookies which are set during your use of our
          Site by changing your browser’s settings. Some pages may not work if
          you completely disable cookies, but many third party cookies can be
          safely blocked.
        </Typography>
        <Typography>
          Check information in your browser’s help section for specific
          instructions on how to manage cookies. Alternatively, websites such as
          “All About Cookies” provide comprehensive guidance.
        </Typography>
        <Typography variant="h3" className="mt-10">
          AUTOMATED PROCESSING AND PROFILING
        </Typography>
        <Typography className="my-4">
          We do not use profiling, unembodied robots, machine learning,
          artificial intelligence or automated decision-making processes to
          process personal information you submit to us.
        </Typography>
        <Typography variant="h3" className="mt-10">
          STORAGE OF YOUR INFORMATION
        </Typography>
        <Typography className="my-4">
          Your information is securely transmitted via our Site using
          commercially reasonable and available security keys and software. Once
          we have received your information, we will also use strict procedures
          and security features, including standard encryption and decryption,
          to prevent unauthorised access.
        </Typography>
        <Typography>
          How long we keep your data depends on the purpose for which the
          information was supplied and on the laws of Ghana.
        </Typography>
        <Typography variant="h3" className="mt-10">
          PASSWORDS
        </Typography>
        <Typography className="my-4">
          Where you have chosen or where we have given you a password to access
          certain parts of the Site, you are responsible for keeping this
          password safe and confidential. You must not share a password with
          anyone. And change your password very often to ensure it continues to
          remain confidential. Change your password and notify us as soon as you
          believe your password has been stolen or compromised in any way.
        </Typography>
        <Typography variant="h3" className="mt-10">
          ONLINE TRANSACTIONS
        </Typography>
        <Typography className="my-4">
          All payment transactions will be encrypted.
        </Typography>
        <Typography>
          Areas of the Site that process online transactions make use of - and
          transfer data to - third party software and service providers. All
          third-party software and service providers that we use are compliant
          with the relevant Payment Card Industry standards.
        </Typography>
        <Typography className="mt-4">
          We will never request you to handover your online payment details
          through an email or other services that lie outside this Site.
        </Typography>
        <Typography variant="h3" className="mt-10">
          DISCLOSURE OF YOUR INFORMATION
        </Typography>
        <Typography className="my-4">
          We will not share, sell or distribute any of the information you
          provide to us that identify you in person, except where we believe in
          good faith that a disclosure is:
        </Typography>
        <ul className="list-disc ml-6">
          <li>
            necessary to enforce our rights, including under our Terms and
            Conditions of use
          </li>
          <li>required or permitted by law</li>
          <li>
            necessary for third parties to offer you products and services that
            may be of interest to you professionally or personally (e.g.,
            business software solutions or member product discounts). Users can
            request their information to be removed from such lists by
            contacting us
          </li>
          <li>
            necessary to ensure the security of your access devices while using
            the Site
          </li>
          <li>
            necessary to increase your visibility to the general public for the
            enhancement and growth of your legal practice and legitimate
            business interests.
          </li>
        </ul>
        <Typography className="mt-4">
          Please note that non-personalised advertising links or other links
          that take you to sites not operated by the GBA, may be subject to the
          privacy policies of such third-party sites.
        </Typography>
        <Typography variant="h3" className="mt-10">
          CHANGES
        </Typography>
        <Typography className="my-4">
          We reserve the right to amend or replace this policy at any time.
        </Typography>
        <Typography variant="h3" className="mt-10">
          ENQUIRIES
        </Typography>
        <Typography className="my-4">
          Please get in touch with us if you have any queries using the
          following contact details.
        </Typography>
        <div className="flex flex-col">
          <Typography className="font-bold">Email:</Typography>
          <Typography>info@ghanabar.org </Typography>
        </div>
        <div className="flex flex-col my-4">
          <Typography className="font-bold">Address:</Typography>
          <Typography>H/NO. 5,</Typography>
          <Typography>2nd Ave. State House Road,</Typography>
          <Typography>
            South-East Ridge, Adjacent to Ghana International Press Centre,
          </Typography>
          <Typography>Accra, Ghana.</Typography>
        </div>
        <Typography className="italic">Revised: January 2, 2024.</Typography>
      </div>
    </>
  );
}

export default Policy;
