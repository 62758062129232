import { Chip, Typography } from "@material-tailwind/react";
import React from "react";

function DuesStatus({ data, title }) {
  // let receiptCheck = false;
  // let getReceipt;

  const receipt = data?.receipts.find(
    (item) => item.post_type === title && item.status === 1
  );

  // if (data?.year === "2023") {
  //   getReceipt = data?.receipts.find(
  //     (item) => item.post_type === "dues" && item.status === 1
  //   );
  //   receiptCheck = getReceipt?.amount < parseInt(data?.amount) ?? false;
  // }

  return (
    <div className="flex ml-2">
      {data.exempt ? (
        <Chip color="indigo" value="Exempted" variant="ghost" />
      ) : receipt ? (
        data.year === "2023" &&
        parseInt(receipt.amount) !== parseInt(data.amount) ? (
          <Chip color="pink" value="Outstanding Balance" variant="ghost" />
        ) : (
          <Chip color="green" value="Paid" variant="ghost" />
        )
      ) : (
        <>
          <Typography>GH¢ {data.amount} </Typography>
          <Chip color="amber" value="Not Paid" variant="ghost" />
        </>
      )}
    </div>

    // <div className="flex ml-2">
    //   {data.exempt ? (
    //     <Chip color="indigo" value="Exempted" variant="ghost" />
    //   ) : receipt ? (
    //     parseInt(receipt.amount) === parseInt(data.amount) ? (
    //       <Chip color="green" value="Paid" variant="ghost" />
    //     ) : (
    //       <Chip color="pink" value="Outstanding Balance" variant="ghost" />
    //     )
    //   ) : (
    //     <Chip color="amber" value="Not Paid" variant="ghost" />
    //   )}
    // </div>
  );
}

export default DuesStatus;
