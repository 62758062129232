import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as api from "../../../redux/api/dues/dues_api";
import * as types from "../../../redux/constants/dues_constants";

import { Button, Spinner } from "@material-tailwind/react";
import toast from "react-hot-toast";
import ConfirmPayment from "../../../common/shared/ConfirmPayment";

function RegionDuesButton({
  post_type,
  amount,
  year,
  id,
  data,
  sub_type,
  inText,
}) {
  const [liveLoading, setLiveLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const handleOpen = () => setOpen(!open);

  const handleLive = async (e) => {
    e.preventDefault();

    const formData = { post_type, amount, year, id, sub_type };
    setLiveLoading(true);

    try {
      const response = await api.duesPayment(formData);
      const { error, data } = response;

      if (error) {
        setLiveLoading(false);
        toast.error(error);
        dispatch({ type: types.DUES_PAYMENT_FAILURE, payload: error });
      } else {
        const firstItem = data.dues.find((item) => item.year === year);

        const matchingReceipt = firstItem.receipts.find(
          (receiptItem) =>
            receiptItem.status === 2 &&
            receiptItem.post_type === post_type &&
            receiptItem.amount === parseInt(amount) &&
            receiptItem.sub_type === sub_type
        );

        if (matchingReceipt && matchingReceipt.url) {
          window.location.href = matchingReceipt.url;
        }
        setLiveLoading(false);
      }
    } catch (error) {
      // window.location.reload();
      console.log(error);
    }
  };

  return (
    <>
      <Button color="pink" onClick={handleOpen} disabled={liveLoading}>
        {liveLoading ? (
          <Spinner color="white" />
        ) : inText ? (
          "Make Payment"
        ) : (
          "E-Payment"
        )}
      </Button>

      {open && (
        <ConfirmPayment
          open={open}
          setOpen={setOpen}
          title="Electronic"
          amount={amount}
          loading={liveLoading}
          handleConfirm={(e) => handleLive(e)}
          handleClose={handleOpen}
        />
      )}
    </>
  );
}

export default RegionDuesButton;
