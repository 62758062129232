export const LOGIN_API = "/auth/login";
export const REGISTER_API = "/auth/register";
export const REGISTER_CHECK = "/auth/check";
export const GET_PROFILE = "/auth/profile";
export const UPDATE_PROFILE = "/auth/update/profile";
export const CHANGE_PASSWORD = "/auth/update/password";
export const UPDATE_PROFILE_IMAGE = "/auth/update/cover";
export const FORGOT_PASSWORD = "/auth/update/forgot";
export const RESET_PASSWORD = "/auth/update/reset";

export const GET_CONFERENCE = "/portal/conference";
export const REGISTER_CONFERENCE = "/portal/conference";
export const CONFERENCE_PAYMENT = "/portal/conference/payment/live";
export const CONFERENCE_MANUAL = "/portal/conference/payment/manual";
export const CONFERENCE_RECEIPT = "/portal/payment/conference/single";
export const CONFERENCE_NEEDS = "/portal/conference/needs";
export const CONFERENCE_BM = "/portal/conference/upload/face";

export const GET_LICENSE = "/portal/license";
export const LICENSE_PAYMENT = "/portal/license";
export const LICENSE_CERTIFICATE = "/portal/license/certificate";
export const MANUAL_LICENSE = "/portal/license/manual";
export const VERIFY_LICENSE_PAYMENT = "/portal/license/verify";
export const FAILED_LICENSE_PAYMENT = "/portal/license/failed";
export const ORDER_LICENSE_REF = "/portal/license/ref";
export const SECOND_LICENSE_VERIFY = "/portal/license/second";
export const SECOND_LICENSE_FAIL = "/portal/license/fail";

export const SINGLE_PUPILAGE = "/portal/pupilage";
export const ADD_PUPILAGE = "/portal/pupilage";
export const CHECK_PUPILAGE = "/portal/pupilage/check";
export const UPDATE_PUPILAGE = "/portal/pupilage";
export const REQUEST_PUPILAGE = "/portal/pupilage/request";
export const ACTION_PUPILAGE = "/portal/pupilage/action";
export const PUPILAGE_CERT = "/portal/pupilage/certificate";
export const PAYMENT_PUPILAGE = "/portal/payment/pupilage";
export const VERIFY_PUPILAGE_PAYMENT = "/portal/pupilage/verify";
export const FAILED_PUPILAGE_PAYMENT = "/portal/pupilage/failed";

export const GET_DUES = "/portal/dues";
export const DUES_PAYMENT = "/portal/dues";
export const NORMAL_DUES = "/portal/dues/normal";
export const UPDATE_DUES_PAYMENT = "/portal/dues/update";
export const VERIFY_DUES_PAYMENT = "/portal/dues/verify";
export const FAILED_DUES_PAYMENT = "/portal/dues/failed";
export const SECOND_DUES_VERIFY = "/portal/dues/second";
export const SECOND_DUES_FAIL = "/portal/dues/fail";

export const GET_CHAMBER = "/portal/chamber";
export const APPLY_CHAMBER = "/portal/chamber";
export const GET_CHAMBER_MEMBERS = "/portal/chamber/member";
export const ADD_CHAMBER_MEMBER = "/portal/chamber/member";
export const REMOVE_CHAMBER_MEMBER = "/portal/chamber/member";
export const UPLOAD_CHAMBER_FILES = "/portal/chamber/upload";
export const UPDATE_CHAMBER = "/portal/chamber/single";
export const SINGLE_USER_CHAMBER = "/portal/chamber/single";
export const CHAMBER_CERTIFICATE = "/portal/chamber/certificate";
export const CHAMBER_PAYMENT = "/portal/chamber/payment";
export const MANUAL_CHAMBER = "/portal/chamber/manual";
export const VERIFY_CHAMBER_PAYMENT = "/portal/chamber/verify";
export const FAILED_CHAMBER_PAYMENT = "/portal/chamber/failed";
export const SECOND_CHAMBER_VERIFY = "/portal/chamber/second";
export const SECOND_CHAMBER_FAIL = "/portal/chamber/fail";
export const REMOVE_CHAMBER_FILE = "/portal/chamber/remove";

export const GET_RECEIPT = "/portal/payment/single";
export const GET_DUES_RECEIPT = "/portal/payment/dues/single";
export const GET_USER_RECEIPT = "/portal/payment/user";

export const GET_GLC_CHAMBER = "/portal/glc/chamber";
export const UPDATE_GLC_CHAMBER = "/portal/glc/chamber";
export const DELETE_GLC_CHAMBER = "/portal/glc/chamber";
export const UPLOAD_GLC_REPORT_CHAMBER = "/portal/glc/chamber/upload";
export const GLC_REPORT_REMOVAL = "/portal/glc/remove/chamber/report";

export const GET_GLC_PUPIL = "/portal/glc/pupils";
export const UPDATE_GLC_PUPIL = "/portal/glc/pupils";

export const GET_USER_STICKERS = "/portal/stickers";
export const APPLY_STICKERS = "/portal/stickers";
export const UPDATE_STICKER = "/portal/stickers";
export const UPLOAD_REG_STICKER = "/portal/stickers/upload/reg";
export const UPLOAD_SUPPORT_STICKER = "/portal/stickers/upload/support";
export const STICKER_PAYMENT = "/portal/stickers/payment";
export const SINGLE_STICKER = "/portal/stickers";

export const GET_USER_NAME = "/main/users/single";
export const UPDATE_SETTING = "/main/users/settings";
export const GET_USER_CHAMBER_NAME = "/main/users/chambers";

export const BOOTCAMP_REGISTRATION = "/portal/bootcamp";
export const BOOTCAMP_USER = "/portal/bootcamp";
export const BOOTCAMP_UPDATE = "/portal/bootcamp/update";
export const BOOTCAMP_LIVE_PAYMENT = "/portal/bootcamp/payment/live";
export const BOOTCAMP_MANUAL_PAYMENT = "/portal/bootcamp/payment/manual";

export const GET_USER_TRAINING = "/portal/training";
export const USER_TRAINING_PAYMENT = "/portal/training/payment";
export const TRAINING_RECEIPT = "/portal/payment/training/single";
