import UpdateProfile from "../pages/user/UserProfile";
import Home from "../pages/home/Home";
import Conference from "../pages/conference/Conference";
import License from "../pages/license/License";
import Pupilage from "../pages/pupilage/Pupilage";
import PupilageEdit from "../pages/pupilage/components/PupilageEdit";
import Dues from "../pages/dues/Dues";
import Chambers from "../pages/chambers/Chambers";
import Resources from "../pages/resources/Resources";
import PupilageRequest from "../pages/pupilage/PupilageRequest";
import PupilageAction from "../pages/pupilage/components/PupilageAction";
import Receipt from "../common/shared/Receipt";
import DuesReceipt from "../pages/dues/common/DuesReceipt";
import ChangePassword from "../pages/auth/ChangePassword";
import NewChambers from "../pages/chambers/NewChambers";
import AddChamberMembers from "../pages/chambers/components/AddChamberMembers";
import FilesChamber from "../pages/chambers/components/FilesChamber";
import EditChamber from "../pages/chambers/components/EditChamber";
import GlcChamber from "../pages/glc/GlcChamber";
import GlcPupilage from "../pages/glc/GlcPupilage";
import LicenseCertificate from "../pages/license/common/LicenseCertificate";
import ChamberCertificate from "../pages/chambers/components/ChamberCertificate";
import PupilCert from "../pages/pupilage/components/PupilCert";
import DuesPaymentPage from "../pages/dues/components/DuesPaymentPage";
import Stickers from "../pages/stickers/Stickers";
import NewStickers from "../pages/stickers/components/NewStickers";
import StickersUpload from "../pages/stickers/components/StickersUpload";
import EditStickers from "../pages/stickers/components/EditStickers";
import DuesInvoice from "../pages/dues/common/DuesInvoice";
import LicenseInvoice from "../pages/license/common/LicenseInvoice";
import LicenseReceipt from "../pages/license/common/LicenseReceipt";
import ChamberReceipt from "../pages/chambers/common/ChamberReceipt";
import AllReceipts from "../pages/receipts/AllReceipts";
import ViewReceipt from "../pages/receipts/ViewReceipt";
import ChamberInvoice from "../pages/chambers/components/ChamberInvoice";
import Settings from "../pages/user/Settings";
import ConferenceInvoice from "../pages/conference/components/ConferenceInvoice";
import ConferenceReceipt from "../pages/conference/components/ConferenceReceipt";
import Bootcamp from "../pages/bootcamp/Bootcamp";
import BootcampReceipt from "../pages/bootcamp/components/BootcampReceipt";
import BootcampInvoice from "../pages/bootcamp/components/BootcampInvoice";
import Training from "../pages/training/Training";
import TrainingInvoice from "../pages/training/components/TrainingInvoice";
import TrainingReceipt from "../pages/training/components/TrainingReceipt";

export const privateRoutes = [
  { path: "/home", element: <Home /> },
  { path: "/home/conferences", element: <Conference /> },
  { path: "/home/conference/invoice", element: <ConferenceInvoice /> },
  { path: "/home/conference/receipt/:id", element: <ConferenceReceipt /> },
  { path: "/home/license", element: <License /> },
  { path: "/home/license/cert", element: <LicenseCertificate /> },
  { path: "/home/license/invoice", element: <LicenseInvoice /> },
  { path: "/home/license/receipt/:id", element: <LicenseReceipt /> },
  { path: "/home/pupilage", element: <Pupilage /> },
  { path: "/home/pupilage/request", element: <PupilageRequest /> },
  { path: "/home/pupilage/action/:id", element: <PupilageAction /> },
  { path: "/home/pupilage/edit", element: <PupilageEdit /> },
  { path: "/home/pupilage/cert", element: <PupilCert /> },
  { path: "/home/dues", element: <Dues /> },
  { path: "/home/dues/receipt/:id", element: <DuesReceipt /> },
  { path: "/home/dues/payment", element: <DuesPaymentPage /> },
  { path: "/home/dues/invoice", element: <DuesInvoice /> },
  { path: "/home/chamber", element: <Chambers /> },
  { path: "/home/chamber/new", element: <NewChambers /> },
  { path: "/home/chamber/members/:id", element: <AddChamberMembers /> },
  { path: "/home/chamber/files/:id", element: <FilesChamber /> },
  { path: "/home/chamber/edit/:id", element: <EditChamber /> },
  { path: "/home/chamber/certificate", element: <ChamberCertificate /> },
  { path: "/home/chambers/receipt/:id", element: <ChamberReceipt /> },
  { path: "/home/chambers/invoice", element: <ChamberInvoice /> },
  { path: "/home/resources", element: <Resources /> },
  { path: "/home/receipt/:id", element: <Receipt /> },
  { path: "/home/update", element: <UpdateProfile /> },
  { path: "/home/password", element: <ChangePassword /> },
  { path: "/home/glc/chamber", element: <GlcChamber /> },
  { path: "/home/glc/pupilage", element: <GlcPupilage /> },
  { path: "/home/sticker", element: <Stickers /> },
  { path: "/home/new/stickers", element: <NewStickers /> },
  { path: "/home/stickers/upload/:id", element: <StickersUpload /> },
  { path: "/home/stickers/edit/:id", element: <EditStickers /> },
  { path: "/home/receipts", element: <AllReceipts /> },
  { path: "/home/receipts/view/:id", element: <ViewReceipt /> },
  { path: "/home/settings", element: <Settings /> },
  { path: "/home/bootcamp", element: <Bootcamp /> },
  { path: "/home/bootcamp/receipt", element: <BootcampReceipt /> },
  { path: "/home/bootcamp/invoice", element: <BootcampInvoice /> },
  { path: "/home/training", element: <Training /> },
  { path: "/home/training/invoice", element: <TrainingInvoice /> },
  { path: "/home/training/receipt/:id", element: <TrainingReceipt /> },
];
