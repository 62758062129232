import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as api from "../../../redux/api/license/license_api";
import * as types from "../../../redux/constants/license_constants";
import toast from "react-hot-toast";
import { Button, Spinner } from "@material-tailwind/react";
import ConfirmPayment from "../../../common/shared/ConfirmPayment";

function LicenseEPaymentButton({ data }) {
  const [liveLoading, setLiveLoading] = useState(false);
  const [liveOpen, setLiveOpen] = useState(false);

  const dispatch = useDispatch();

  const handleLiveOpen = () => setLiveOpen(!liveOpen);

  const handleLive = async (e) => {
    e.preventDefault();
    const sendData = {
      amount: data.amount,
      year: data.year,
      id: data.id,
    };

    setLiveLoading(true);

    try {
      const response = await api.licensePayment(sendData);
      const { error, data } = response;

      if (error) {
        setLiveLoading(false);
        setLiveOpen(false);
        toast.error(error);
        dispatch({ type: types.LICENSE_PAYMENT_FAILURE, payload: error });
      } else {
        const firstItem = data.license.find(
          (item) => item.year === sendData.year
        );

        const foundReceipt = firstItem.receipts.find(
          (item) => item.status === 2 && item.method === 1
        );

        if (foundReceipt && foundReceipt.url) {
          window.location.href = foundReceipt.url;
        }
        setLiveLoading(false);
      }
    } catch (error) {
      setLiveOpen(false);
      // window.location.reload();
      console.log(error);
    }
  };

  return (
    <>
      <Button
        disabled={
          !data.standing ||
          !data.pupilStatus ||
          data?.receipt ||
          (!data.manualGreen &&
            !data.previousReceipt &&
            data.length !== 1 &&
            !data.passManual) ||
          (!data.isRegion && parseInt(data.year) >= 2024) ||
          liveLoading
        }
        color="pink"
        onClick={handleLiveOpen}
      >
        {liveLoading ? <Spinner color="white" /> : "Make Payment"}
      </Button>

      {liveOpen && (
        <ConfirmPayment
          open={liveOpen}
          setOpen={setLiveOpen}
          title="Electronic"
          amount={data.amount}
          loading={liveLoading}
          handleConfirm={(e) => handleLive(e)}
          handleClose={handleLiveOpen}
        />
      )}
    </>
  );
}

export default LicenseEPaymentButton;
