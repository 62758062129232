import React from "react";
import SectionTitle from "../../common/shared/SectionTitle";
import { Typography } from "@material-tailwind/react";
import logo from "../../assets/img/gba-logo.png";

function TAC() {
  return (
    <>
      <SectionTitle title={"Terms & Conditions of use"} description={""} />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        <div className="text-center">
          <img src={logo} alt="logo" className="h-30 inline-block" />

          <Typography variant="h2" className="my-10">
            GBA Online Software: T&C
          </Typography>
        </div>

        <Typography>
          This portal, website, social media platform, blog, vlog, online
          data/discussion forum, mobile app (the “Site”) has been designed and
          provided by the Ghana Bar Association (GBA). Use of the Site,
          howsoever your means of access, use, interaction or participation and
          whatsoever your duration of access, use, interaction or participation,
          is governed by these terms of use (“T&C”).
        </Typography>

        <Typography className="mt-4">
          This T&C is a browse-wrap contract between the GBA and yourself; which
          means that by merely visiting the Site you are deemed to have read
          this contract, that you accept the T&C, that you agree to abide by
          them, and that you agree to be bound by its terms. This agreement
          together with all revisions and modifications constitute the entire
          agreement between the GBA and yourself. If you are unable or not
          willing to accept these Terms and Conditions you may immediately exit,
          terminate, close or log out and not use the Site.
        </Typography>

        <Typography variant="h3" className="mt-10">
          ACCESS
        </Typography>
        <Typography className="my-4">
          Access to the Site is permitted on a temporary basis, and we reserve
          the right to withdraw or amend the service we provide on the Site
          without notice. While the GBA shall endeavour always to keep the Site
          up and running, there may be times when the Site may be completely out
          of use, shut down or intermittently inaccessible to some or all users.
        </Typography>
        <Typography>
          From time to time, we may restrict access to some parts of the Site,
          or the entire Site, to users. If you choose, or you are provided with,
          a user identification symbol, code, passphrase, password, token or any
          other piece of information as part of our access security procedures,
          you must treat such information as confidential, and you may not
          disclose it to any other person. We have the right to disable any user
          access security procedure or code, whether chosen by you or provided
          by us, if in our opinion you have not complied with any of the
          provisions of these terms of use.
        </Typography>
        <Typography variant="h3" className="mt-10">
          DISCLAIMER
        </Typography>
        <Typography className="my-4">
          As permitted by law, this Site together with its contents is provided
          on "as is" basis. We disclaim all representations, assumptions or
          warranties, express or implied, with respect to this Site or the
          information, content, products, output, services, references or
          linkages included in this Site including warranties of merchantability
          and fitness for any purpose. We make no representation or warrant of
          any kind that the information retrievable or accessible via this Site
          is accurate, complete, up-to-date or current. Under no circumstances,
          as fully permitted by law, shall the GBA or any of its officers,
          hires, contractors, agents or employees be liable for any loss,
          injury, pain, cost or damage howsoever incurred or arising suffered as
          a result of any use of or interaction with this Site or its
          information, content, products, output, services, references or
          linkages.
        </Typography>
        <Typography>
          We make no guarantees whatsoever that the Site is free from bugs,
          errors, worms, viruses or malware in general. You accept that the
          protection of your computer systems or means of access to this Site
          from the effects of any malware is your responsibility wholly and
          solely.
        </Typography>
        <Typography variant="h3" className="mt-10">
          LINKS
        </Typography>
        <Typography className="my-4">
          Links or references to any third party sites do not imply any
          endorsement or approval by the GBA of the materials, contents or
          output of such websites.
        </Typography>
        <Typography variant="h3" className="mt-10">
          LIABILITY
        </Typography>
        <Typography className="my-4">
          The GBA accepts no liability for any claims, penalties, loss or
          expenses arising from: any reliance placed on the content of the Site,
          the use or inability to use the Site, the downloading of any
          information from the Site – whether placed on the Site by the GBA or
          another user, or any unauthorised access to or alteration to the Site.
        </Typography>
        <Typography variant="h3" className="mt-10">
          COPYRIGHT
        </Typography>
        <Typography className="my-4">
          The content of the Site (all text and artwork included without
          limitation) is protected by copyright. The copyright is owned by the
          GBA or is otherwise licensed from a third party for use by the GBA.
        </Typography>
        <Typography>
          You may view or download any part of the Site for private purposes,
          but you are not permitted, without our permission, to:
        </Typography>
        <ul className="list-disc ml-10">
          <li>
            store the Site, or any part of the Site, for any other purpose;
          </li>
          <li>
            compile or recompose or recombine contents of the Site or any part
            thereof, for any other purpose;
          </li>
          <li>
            print copies of the Site, or any part of the Site, for any other
            purpose;
          </li>
          <li>
            reproduce, copy or transmit the Site, or any part of the Site, in
            any way, for any other purpose or in any other medium.
          </li>
        </ul>
        <Typography variant="h3" className="mt-10">
          PRIVACY NOTICE
        </Typography>
        <Typography className="my-4">
          On visiting or using the Site we sometimes need to collect information
          on you or about you. We are committed to preserving your privacy. How
          we use this information is explained in our privacy policy which is
          available on the first page of this Site. You agree that we use this
          information collected on you or about you to serve you or the larger
          body of lawyers in Ghana, the judicial service of Ghana, users or
          potential users of the court or judicial system of Ghana.
        </Typography>
        <Typography variant="h3" className="mt-10">
          PUBLISHING THROUGH THE SITE
        </Typography>
        <Typography className="my-4">
          Without specifically targeting you using your personal data, you agree
          that the GBA may publish advertising and promotional content for the
          sole purpose of raising funds for the GBA.
        </Typography>
        <Typography>
          If you wish to publish, promote any material or sell merchandise
          through the Site, please be aware that other terms and conditions in
          addition to this T&C may apply to such publications.
        </Typography>
        <Typography variant="h3" className="mt-10">
          UPLOADING MATERIAL TO THE SITE
        </Typography>
        <Typography className="my-4">
          Other than information that personally identify you and your date of
          birth, which is covered under our Privacy Policy, any content or
          material you transmit or post to the Site is deemed to be
          non-confidential and non-proprietary. We shall have no obligations
          with respect to your content and materials and shall have the right to
          use, copy, distribute and disclose to third parties any such content
          or material for any purpose. We also have the right to disclose your
          identity to any third party who is claiming that any material posted
          or uploaded by you to the Site constitutes a violation of their
          intellectual property rights, or of their right to privacy.
        </Typography>
        <Typography>
          You are prohibited from posting or transmitting to or from the Site
          any content or material:
        </Typography>
        <ul className="list-disc ml-10">
          <li>
            which you know or ought to know that it is inaccurate, untrue or
            misleading; or
          </li>
          <li>
            which constitutes an unauthorized posting of personal information
            (Bar number, names, address, phone number, email, etc.) of other
            users; or
          </li>
          <li>
            that is threatening, defamatory, obscene, indecent, seditious,
            offensive, pornographic, abusive, liable to incite racial hatred,
            discriminatory, menacing, scandalous, inflammatory, blasphemous, in
            breach of confidence, in breach of privacy or which may cause
            annoyance or inconvenience; or
          </li>
          <li>
            for which you have not obtained the necessary licences or approvals;
            or
          </li>
          <li>
            which constitutes or encourages conduct that would be considered a
            criminal offence, give rise to civil liability, or otherwise be
            contrary to any law or principle of equity or infringe the rights of
            any third party; or
          </li>
          <li>
            which is harmful in any way (including, without limitation, computer
            viruses, logic bombs, Trojan horses, worms, harmful components,
            corrupted data or other malicious software, harmful data, harmful
            solicitation, social engineering or malware of any kind); or
          </li>
          <li>
            which is advertising or other commercial solicitations without
            permission; or
          </li>
          <li>which constitutes opinions of fictitious or third parties.</li>
        </ul>
        <Typography className="my-4">
          You may not misuse the Site (including, without limitation, by
          hacking).
        </Typography>
        <Typography>
          You are prohibited from using bots or any other unembodied robotic or
          artificial intelligence software in completing forms on this Site.
        </Typography>
        <Typography>
          We have the right to remove any material or posting you make on the
          Site if, in our opinion, such material does not comply with the
          provisions set out above or for any other reason whatsoever. In
          addition, we shall fully co-operate with any law enforcement
          authorities or court order requesting or directing us to disclose the
          identity or locate anyone posting any material in breach of the
          provisions set out above.
        </Typography>
        <Typography variant="h3" className="mt-10">
          STORING YOUR INFORMATION
        </Typography>
        <Typography className="my-4">
          You agree to our use of cloud servers procured for the use of the GBA
          to store your personal data.
        </Typography>
        <Typography>
          You agree to our employment of third-party companies for services that
          will enhance the quality, service and functionality of the services
          available on our Site.
        </Typography>
        <Typography variant="h3" className="mt-10">
          ONLINE TRANSACTIONS
        </Typography>
        <Typography className="my-4">
          We accept information from you to effect online payment for services
          that you order on our Site. All payment transactions will be
          encrypted.
        </Typography>
        <Typography>
          We deem it your sole responsibility to ensure that it is safe to
          handover your personal information for online payment, such as your
          card details, before you proceed to handover such information to our
          Site. You must always ensure to abide by the security protocols or
          measures of your bank or online payment service provider before you
          handover your online payment details to our Site.
        </Typography>
        <Typography variant="h3" className="mt-10">
          PASSWORDS
        </Typography>
        <Typography className="my-4">
          Where you have chosen or where we have given you a password to access
          certain parts of the Site, you are responsible for keeping this
          password safe and confidential. You must not share a password with
          anyone. And change your password very often to ensure it continues to
          remain confidential. Change your password and notify us as soon as you
          believe your password has been stolen or compromised in any way.
        </Typography>
        <Typography variant="h3" className="mt-10">
          CHANGES
        </Typography>
        <Typography className="my-4">
          We reserve the right to amend or replace this T&C at any time. If we
          make any substantial changes we will notify you by posting a prominent
          notice on the Site.
        </Typography>
        <Typography>
          Your use of the Site shall indicate your acceptance of any
          modifications made at any time. When data or information pertaining to
          you changes, we deem it your sole responsibility to take steps to
          inform us and to ensure that such changes are effected on our Site.
        </Typography>
        <Typography variant="h3" className="mt-10">
          JURISDICTION
        </Typography>
        <Typography className="my-4">
          The T&C shall be governed by and construed in accordance with the Laws
          of Ghana and any disputes that may arise will be subject to the
          jurisdiction of the courts of Ghana.
        </Typography>
        <Typography variant="h3" className="mt-10">
          CONTINUED USE OF SITE
        </Typography>
        <ul className="list-disc my-4 ml-10">
          <li>
            Access to the Site is at the sole discretion of the GBA. We retain
            the right to block, remove, edit or reject postings or suspend
            access as we deem appropriate.
          </li>
          <li>
            Posts, content or other material which is deemed inappropriate by
            the site administrators or appropriate team managers may be deleted
            upon discovery. Users who violate this T&C in any way may lose their
            accounts permanently.
          </li>
        </ul>
        <Typography variant="h3" className="mt-10">
          ENQUIRIES
        </Typography>
        <Typography className="my-4">
          Please get in touch with us if you have any queries using the
          following contact details.
        </Typography>
        <div className="flex flex-col">
          <Typography className="font-bold">Email:</Typography>
          <Typography>info@ghanabar.org </Typography>
        </div>
        <div className="flex flex-col my-4">
          <Typography className="font-bold">Address:</Typography>
          <Typography>H/NO. 5,</Typography>
          <Typography>2nd Ave. State House Road,</Typography>
          <Typography>
            South-East Ridge, Adjacent to Ghana International Press Centre,
          </Typography>
          <Typography>Accra, Ghana.</Typography>
        </div>
        <Typography className="italic">Revised: January 2, 2024.</Typography>
      </div>
    </>
  );
}

export default TAC;
