import { Button } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function TrainingPayment({ data, disable }) {
  const navigate = useNavigate();
  const formData = { data };

  console.log("-----------------------");
  console.log(data);

  return (
    <Button
      color="pink"
      onClick={() => navigate("/home/training/invoice", { state: formData })}
      disabled={disable}
    >
      Make Payment
    </Button>
  );
}

export default TrainingPayment;
