import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as api from "../../../redux/api/training/training_api";
import * as types from "../../../redux/constants/training_constants";
import { Button, Spinner } from "@material-tailwind/react";

function TrainingPaymentButton({ amount, year, id, sub_type }) {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleLive = async (e) => {
    e.preventDefault();

    setLoading(true);

    const formData = {
      amount,
      year,
      id,
      sub_type,
    };

    try {
      const response = await api.userTrainingPayment(formData);
      const { error, data } = response;

      if (error) {
        setLoading(false);
        dispatch({ type: types.USER_TRAINING_PAYMENT_FAILURE, payload: error });
      } else {
        window.location.href = data.receipt.url;
      }
    } catch (error) {
      window.location.reload();
      console.log(error);
    }
  };

  return (
    <Button color="pink" onClick={(e) => handleLive(e)}>
      {loading ? <Spinner color="white" /> : "Make Payment"}
    </Button>
  );
}

export default TrainingPaymentButton;
