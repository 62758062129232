import {
  GET_USER_TRAINING,
  USER_TRAINING_PAYMENT,
  TRAINING_RECEIPT,
} from "../../constants/api_constants";
import { handleApi } from "../utils";

export const getUserTraining = async () => {
  return handleApi("get", GET_USER_TRAINING);
};

export const userTrainingPayment = async (values) => {
  return handleApi("post", USER_TRAINING_PAYMENT, values);
};

export const userTrainingReceipt = async (id) => {
  return handleApi("get", `${TRAINING_RECEIPT}/${id}`);
};
