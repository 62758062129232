import * as types from "../constants/training_constants";

const initialState = {
  data: null,
  trainingError: null,
};

const trainingReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_USER_TRAINING_SUCCESS:
    case types.USER_TRAINING_PAYMENT_SUCCESS:
    case types.USER_TRAINING_RECEIPT_SUCCESS:
      return { ...state, data: payload ? payload : null, trainingError: null };

    case types.USER_TRAINING_PAYMENT_FAILURE:
    case types.GET_USER_TRAINING_FAILURE:
    case types.USER_TRAINING_RECEIPT_FAILURE:
      return { ...state, trainingError: payload ? payload : null };

    default:
      return state;
  }
};

export default trainingReducer;
