import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerConference } from "../../../redux/actions/conference_action";
import { Button, Spinner, Typography } from "@material-tailwind/react";
import OpenModal from "../../../common/actions/OpenModal";
import AlertMessage from "../../../common/actions/AlertMessage";

function ConferenceRegister({ item, disabled = false }) {
  const [loading, setLoading] = useState(false);
  const [virtualloading, setVirtualLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [value, setValue] = useState("");

  const dispatch = useDispatch();

  const handleModal = (value) => {
    setValue(value);
    setShowModal(!showModal);
  };

  const handleConfirmation = () => {
    setShowConfirmation(!showConfirmation);
  };

  const handleRegister = async (value) => {
    if (value === "In-Person") {
      setLoading(true);
    } else {
      setVirtualLoading(true);
    }

    const sendData = { ...item, sub_type: value };

    await dispatch(registerConference(sendData)).finally(() => {
      setLoading(false);
      setVirtualLoading(false);
      setShowModal(false);
    });
  };

  return (
    <>
      <div className="flex flex-col gap-4 md:flex-row md:items-center mb-2">
        <Button
          color="green"
          onClick={() => handleModal("In-Person")}
          fullWidth
          size="lg"
        >
          {loading ? (
            <Spinner color="white" />
          ) : (
            <>
              Click Here for <span className="text-3xl">In-Person</span>{" "}
              Participation
            </>
          )}
        </Button>

        <Button
          color="light-blue"
          onClick={() => handleModal("Virtual")}
          fullWidth
        >
          {virtualloading ? (
            <Spinner color="white" />
          ) : (
            <>
              Click Here for <span className="text-3xl">Virtual</span>
              Participation
            </>
          )}
        </Button>
      </div>

      {showModal && (
        <OpenModal
          open={showModal}
          handler={() => handleModal(value)}
          title="Confirm Participation"
          loading={loading}
          actionTitle="Confirm"
          handleSubmit={() => handleRegister(value)}
          children={
            <>
              <Typography color="black" className="text-xl">
                Please confirm {value} Participation
              </Typography>
            </>
          }
        />
      )}

      {showConfirmation && (
        <>
          <Typography>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatum
            est optio voluptas, rerum officia nam laboriosam tenetur culpa
            maxime harum adipisci mollitia facilis totam omnis in molestias cum
            nobis commodi!
          </Typography>
          <OpenModal
            open={showConfirmation}
            handler={handleConfirmation}
            title="Success"
            handleSubmit={() => setShowConfirmation(false)}
            children={
              <>
                <Typography color="black" className="text-xl">
                  Conference registration successful.
                </Typography>
              </>
            }
          />
        </>
      )}
    </>
  );
}

export default ConferenceRegister;
