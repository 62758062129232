import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SectionTitle from "../../../common/shared/SectionTitle";
import {
  Button,
  Card,
  CardBody,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Typography,
} from "@material-tailwind/react";
import logo from "../../../assets/img/gba-logo.png";
import moment from "moment";
import toast from "react-hot-toast";
import jsPDF from "jspdf";
import LicenseManualButton from "../components/LicenseManualButton";
import LicenseEPaymentButton from "../components/LicenseEPaymentButton";
import ReactToPrint from "react-to-print";

function LicenseInvoice() {
  const [type, setType] = useState("");
  const [open, setOpen] = useState(false);

  const location = useLocation();

  const user = useSelector((state) => state.auth?.userData);

  const handleOpen = () => setOpen(!open);
  const handleType = (name) => setType(name);

  const { licenseError } = useSelector((state) => state.license);

  const licenseData = location.state?.formData.data;

  let cardBodyRef = useRef();

  // const handleGeneratePDF = (name) => {
  //   const content = document.getElementById("content-to-pdf");
  //   const doc = new jsPDF("p", "pt", "a4");

  //   doc.html(content, {
  //     callback: function (doc) {
  //       doc.autoPrint();
  //       window.open(doc.output("bloburl"), "_blank");
  //       setOpen(false);
  //     },
  //     margin: [10, 10, 10, 10],
  //     html2canvas: {
  //       scale: 0.62,
  //       logging: false,
  //       useCORS: true,
  //     },
  //     x: 10,
  //     y: 10,
  //     width: 180,
  //     windowWidth: 900,
  //   });
  // };

  return (
    <div className="my-8 max-w-[1200px] m-auto px-4">
      <SectionTitle title="License Invoice" description="" children={[]} />

      <Typography className="text-center mb-4" variant="h4">
        Please select invoice type to generate invoice and proceed to make
        payment
      </Typography>

      {/* {licenseError && <AlertMessage type="error" message={licenseError} />} */}

      {/* <div className="flex items-center justify-center gap-3 mb-8">
        <div>
          <Button onClick={handleOpen}>Print Invoice</Button>
        </div>


      </div> */}

      <div className="flex items-center justify-center gap-3 mb-8">
        <Button
          color="green"
          onClick={() => handleType("manual")}
          fullWidth
          size="lg"
          className="text-sm sm:text-lg md:text-2xl"
        >
          Manual Payment Invoice
        </Button>
        <Button
          color="light-blue"
          onClick={() => handleType("electronic")}
          fullWidth
          size="lg"
          className="text-base sm:text-lg md:text-2xl"
        >
          E-Payment Invoice
        </Button>
      </div>

      <div className="flex flex-col items-center justify-center">
        <Typography variant="h5" color="red" className="mb-4">
          Electronic Payment attracts a payment charge of 2% (GH¢
          {parseFloat(licenseData.amount * 0.02).toFixed(2)})
        </Typography>

        <Typography variant="h5" color="red">
          Manual Payment attracts a payment charge of 3% (GH¢
          {parseFloat(licenseData.amount * 0.03).toFixed(2)})
        </Typography>
      </div>

      {type && (
        <>
          <div className="flex items-center justify-center gap-3 mb-8">
            <ReactToPrint
              trigger={() => <Button>Print Invoice</Button>}
              content={() => cardBodyRef}
              removeAfterPrint={false}
            />

            {type === "electronic" ? (
              <LicenseEPaymentButton data={licenseData} />
            ) : (
              <LicenseManualButton data={licenseData} />
            )}
          </div>

          <Card>
            <CardBody ref={(el) => (cardBodyRef = el)}>
              <img src={logo} alt="logo" className="h-20" />

              <div className="mt-5 pl-3 flex justify-between">
                <div>
                  <Typography variant="h5">Ghana Bar Association</Typography>
                  <Typography variant="paragraph">
                    H/NO. 5, 2nd Ave. State House Road,
                  </Typography>
                  <Typography variant="paragraph">
                    South-East Ridge, Adjacent to
                  </Typography>
                  <Typography variant="paragraph">
                    Ghana International Press Centre,
                  </Typography>
                  <Typography variant="paragraph">Accra</Typography>
                </div>

                <div>
                  <Typography variant="h5">
                    Billed to:{" "}
                    {user.title + " " + user.first_name + " " + user.last_name}
                  </Typography>
                  <Typography variant="paragraph">
                    Email Address: {user.email}
                  </Typography>
                  <Typography variant="paragraph">
                    Phone #: {user.phone}
                  </Typography>

                  <div className="mt-20">
                    <Typography variant="h6" color="gray">
                      Invoice Date
                    </Typography>
                    <Typography variant="h4">
                      {moment().format("MMMM Do, YYYY")}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className="mt-24">
                <table className="w-full min-w-max table-auto text-left">
                  <thead>
                    <tr>
                      <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          Description
                        </Typography>
                      </th>
                      <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          Qty
                        </Typography>
                      </th>
                      <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          Amount
                        </Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {licenseData.name}
                        </Typography>
                      </td>

                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          1
                        </Typography>
                      </td>

                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {licenseData.amount}
                        </Typography>
                      </td>
                    </tr>

                    {type && (
                      <tr>
                        <td className="p-4 border-b border-blue-gray-50">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {type === "electronic"
                              ? "E-Payment"
                              : "Manual Payment"}{" "}
                            Processing Charges
                          </Typography>
                        </td>

                        <td className="p-4 border-b border-blue-gray-50">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            1
                          </Typography>
                        </td>

                        <td className="p-4 border-b border-blue-gray-50">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {type === "electronic"
                              ? licenseData.amount * 0.02
                              : licenseData.amount * 0.03}
                          </Typography>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-semibold"
                        >
                          Total
                        </Typography>
                      </td>

                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          GH¢
                        </Typography>
                      </td>

                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-semibold"
                        >
                          {parseInt(licenseData.amount) *
                            (type === "electronic"
                              ? 1.02
                              : type === "manual"
                              ? 1.03
                              : 1)}
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mt-40">
                <Typography variant="h4">Thank you!</Typography>
                <Typography variant="paragraph">
                  If you encounter any issue related to the invoice you can
                  contact us at:
                </Typography>

                <Typography variant="paragraph">
                  email:{" "}
                  <span className="font-semibold">info@ghanabar.org</span>
                </Typography>
              </div>
            </CardBody>
          </Card>
        </>
      )}

      {open && (
        <Dialog open={open} size="sm" handler={handleOpen}>
          <DialogHeader>Inovice Type</DialogHeader>
          <DialogBody>
            <Typography color="black" variant="lead" className="mb-2">
              Please select invoice type.
            </Typography>
          </DialogBody>
          <DialogFooter className="flex justify-between">
            <Button
              // onClick={() => handleGeneratePDF("electronic")}
              color="pink"
            >
              Electronic Payment Invoice
            </Button>

            <Button color="amber">Manual Payment Invoice</Button>
          </DialogFooter>
        </Dialog>
      )}
    </div>
  );
}

export default LicenseInvoice;
