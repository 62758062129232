import React from "react";
import SectionTitle from "../../common/shared/SectionTitle";
import logo from "../../assets/img/gba-logo.png";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";

function Disclaimer() {
  return (
    <>
      <SectionTitle title={"Disclaimer"} description={""} />

      <div className="my-8 max-w-[1200px] m-auto px-4 text-center">
        <img src={logo} alt="logo" className="h-30 inline-block" />

        <Typography variant="h2" className="mt-10">
          GBA Online Software: Disclaimer
        </Typography>

        <Typography className="mt-4">
          As fully permitted by law, the Ghana Bar Association (GBA) provides
          this portal and website, together with its contents on "as is" basis.
          The GBA disclaims all representations, assumptions or warranties,
          express or implied, with respect to this portal and website or the
          information, content, products, output, services, references or
          linkages included in this portal and website including warranties of
          merchantability and fitness for any purpose. The GBA makes no
          representation or warrant of any kind that the information retrievable
          or accessible via this portal and website is accurate, complete,
          up-to-date or current. Under no circumstances, as fully permitted by
          law, shall the GBA or any of its officers, hires, contractors, agents
          or employees be liable for any loss, injury, pain, cost or damage
          howsoever incurred or arising suffered as a result of any use of or
          interaction with this portal and website or its information, content,
          products, output, services, references or linkages
        </Typography>

        <Typography className="mt-4">
          `This portal and website was developed by the Ghana Bar Association
          Copyright © 2018 - {new Date().getFullYear()} – Ghana Bar Association.
          All rights reserved. Your mere visit or use of this portal and website
          binds you in a browse-wrap contract in our{" "}
          <span className="text-pink-600">
            <Link to={"/terms"}>
              Terms of Use which are available by clicking here
            </Link>
          </span>
          `
        </Typography>

        <Typography className="mt-4">
          ` You may read our Privacy Policy by{" "}
          <span className="text-pink-600">
            <Link to={"/policy"}>clicking here.</Link>
          </span>
          `
        </Typography>
      </div>
    </>
  );
}

export default Disclaimer;
