import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { licenseManual } from "../../../redux/actions/license_action";
import {
  Button,
  Dialog,
  DialogFooter,
  DialogHeader,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import ConfirmPayment from "../../../common/shared/ConfirmPayment";

function LicenseManualButton({ data }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [manualOpen, setManualOpen] = useState(false);

  const dispatch = useDispatch();

  const handleOpen = () => setOpen(!open);
  const handleManualOpen = () => setManualOpen(!manualOpen);

  const handleManual = (e) => {
    e.preventDefault();
    const formData = {
      amount: data.amount,
      year: data.year,
      id: data.id,
    };

    setLoading(true);
    dispatch(licenseManual(formData)).finally(() => {
      setLoading(false);
      setOpen(true);
    });
  };

  // const handleOpen = () => setOpen(!open);

  return (
    <>
      <Button
        disabled={
          !data.standing ||
          !data.pupilStatus ||
          data?.receipt ||
          (!data.manualGreen &&
            !data.previousReceipt &&
            data.length !== 1 &&
            !data.passManual) ||
          (!data.isRegion && parseInt(data.year) >= 2024)
        }
        color="amber"
        onClick={handleManualOpen}
      >
        {loading ? <Spinner color="white" /> : "Process Payment"}
      </Button>

      {open && (
        <Dialog open={open} handleOpen={handleOpen}>
          <DialogHeader>
            <Typography variant="h5" color="blue-gray">
              Your manual payment would be processed at the Bar Secretariat
            </Typography>
          </DialogHeader>
          <DialogFooter>
            <Button variant="text" color="blue-gray" onClick={handleOpen}>
              Close
            </Button>
          </DialogFooter>
        </Dialog>
      )}

      {manualOpen && (
        <ConfirmPayment
          open={manualOpen}
          setOpen={setManualOpen}
          title="Manual"
          amount={data.amount}
          loading={loading}
          handleConfirm={(e) => handleManual(e)}
          handleClose={handleManualOpen}
        />
      )}
    </>
  );
}

export default LicenseManualButton;
