import createAsyncAction from "../../utils/asyncAction";
import * as api from "../api/training/training_api";
import * as types from "../constants/training_constants";

const getUserTraining = {
  success: types.GET_USER_TRAINING_SUCCESS,
  failure: types.GET_USER_TRAINING_FAILURE,
};

const userTrainingPayment = {
  success: types.USER_TRAINING_PAYMENT_SUCCESS,
  failure: types.USER_TRAINING_PAYMENT_FAILURE,
};

const userTrainingReceipt = {
  success: types.USER_TRAINING_RECEIPT_SUCCESS,
  failure: types.USER_TRAINING_RECEIPT_FAILURE,
};

export const getUserTrainingAction = createAsyncAction(
  api.getUserTraining,
  getUserTraining
);

export const userTrainingPaymentAction = createAsyncAction(
  api.userTrainingPayment,
  userTrainingPayment
);

export const userTrainingReceiptAction = createAsyncAction(
  api.userTrainingReceipt,
  userTrainingReceipt
);
