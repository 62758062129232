import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import Disclaimer from "../pages/misc/Disclaimer";
import Policy from "../pages/misc/Policy";
import TAC from "../pages/misc/TAC";
import ForgotPassword from "../pages/user/ForgotPassword";
import ResetPassword from "../pages/user/ResetPassword";

export const publicRoutes = [
  { path: "/", element: <SignIn /> },
  { path: "/signup", element: <SignUp /> },
  { path: "/forgot", element: <ForgotPassword /> },
  { path: "/user/reset-password/:id", element: <ResetPassword /> },
  { path: "/disclaimer", element: <Disclaimer /> },
  { path: "/terms", element: <TAC /> },
  { path: "/policy", element: <Policy /> },
];
