import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
//import { createCanvas } from "canvas";
import { Months } from "../../../utils/months";
import SectionTitle from "../../../common/shared/SectionTitle";
import { Button } from "@material-tailwind/react";
import Loader from "../../../common/loader/Loader";
import ReactToPrint from "react-to-print";

function ChamberCertificate() {
    const [certificateImage, setCertificateImage] = useState(null);

    let componentRef = useRef();
    const canvasRef = useRef();

    const location = useLocation();

    const certData = location.state?.formData;

    const formData = useMemo(
        () => ({
            year: certData?.year,
            cert_number: certData?.cert_number,
            name: certData.name,
            exp_date: certData?.exp_date,
        }),
        [certData]
    );

    useEffect(() => {
        const generateCertificate = async () => {
            try {
                const image = new Image();
                image.src = `/cert/chamber/chamber${formData.year}.jpg`;

                // Wait for the image to load
                image.onload = async () => {
                    await image.decode();

                    const canvas = canvasRef.current;
                    if (!canvas) {
                        console.error("Canvas not found");
                        return;
                    }
                    const ctx = canvas.getContext("2d");
                    if (!ctx) {
                        console.error("Context not found");
                        return;
                    }

                    canvas.width = image.width;
                    canvas.height = image.height;

                    ctx.drawImage(image, 0, 0);
                    ctx.font = "bold 25px Arial";
                    ctx.fillText(formData.cert_number, 680, 137);

                    const nameText = formData.name;
                    // ctx.font = "bold 20px Arial";
                    const textWidth = ctx.measureText(nameText).width;
                    const xCoordinate = (canvas.width - textWidth) / 2;
                    ctx.fillText(nameText.toUpperCase(), xCoordinate, 635);

                    const date = new Date(formData.exp_date);
                    const day = date.getDate();
                    const month = Months[date.getMonth()];
                    const yearDate = formData.year;
                    // const yearDate = date.getFullYear() - 1;

                    ctx.fillText(day, 250, 820);
                    ctx.fillText(month, 500, 820);
                    ctx.fillText(yearDate % 100, 750, 825);

                    const container = document.getElementById("certificateCanvasContainer");
                    if (container) {
                        container.innerHTML = "";

                        container.appendChild(canvas);
                    } else {
                        console.error("Certificate container not found");
                    }

                    const dataUrl = canvas.toDataURL("image/png");
                    setCertificateImage(dataUrl);
                };

                image.onerror = (err) => {
                    console.error("Image loading error:", err);
                };
            } catch (error) {
                console.error("Error generating certificate:", error);
            }
        };

        generateCertificate();
    }, [formData]);

    return (
        <>
            <SectionTitle title="Chamber Certificate" description="" children={[]} />

            <div className="my-8 max-w-[1200px] m-auto px-4 flex flex-col items-center justify-center">
                <div className="flex items-center gap-3 mb-8">
                    <div>
                        <ReactToPrint
                            trigger={() => <Button>Print Cert</Button>}
                            content={() => componentRef}
                            removeAfterPrint={false}
                        />
                    </div>
                </div>

                <canvas ref={canvasRef} className="hidden" />

                {!certificateImage ? (
                    <Loader />
                ) : (
                    <div ref={(el) => (componentRef = el)}>
                        <img src={certificateImage} alt="Generated Certificate" />
                    </div>
                )}
            </div>
        </>
    );
}

export default ChamberCertificate;
