import React, { useState } from "react";
import { API } from "../../redux/api/utils";
import { GET_USER_CHAMBER_NAME } from "../../redux/constants/api_constants";
import { Input, Spinner, Typography } from "@material-tailwind/react";

function ChamberCheck({
  labelTitle,
  type,
  containerStyle,
  defaultValue,
  placeholder,
  updateFormValue,
  updateType,
  required = false,
  maxLength,
}) {
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [chamberText, setChamberText] = useState(null);

  const updateInputValue = (val) => {
    setValue(val);
    setErrorText(null);
    setChamberText(null);
    updateFormValue({ updateType, value: val });
  };

  const checkChamber = async () => {
    setLoading(true);
    try {
      const response = await API({
        method: "get",
        url: `${GET_USER_CHAMBER_NAME}/${value}`,
      });

      setChamberText(response.data.chamber);
    } catch (e) {
      setChamberText(null);
      setErrorText(e.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`form-control w-full ${containerStyle}`}>
      <Input
        type={type || "text"}
        label={labelTitle}
        variant="outlined"
        size="lg"
        value={value}
        placeholder={placeholder || ""}
        onChange={(e) => updateInputValue(e.target.value)}
        required={required}
        color="black"
        maxLength={maxLength ? maxLength : null}
        icon={loading && <Spinner color="pink" className="h-4 w-4" />}
        onBlur={checkChamber}
      />
      {errorText ? (
        <Typography variant="small" color="red">
          Error: {errorText}
        </Typography>
      ) : chamberText ? (
        chamberText && (
          <Typography variant="small">{chamberText?.name}</Typography>
        )
      ) : null}
    </div>
  );
}

export default ChamberCheck;
