import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import SectionTitle from "../../../common/shared/SectionTitle";
import {
  Button,
  Card,
  CardBody,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import ChamberPayment from "./ChamberPayment";
import { manualChamber } from "../../../redux/actions/chamber_actions";
import moment from "moment";
import logo from "../../../assets/img/gba-logo.png";
import ConfirmPayment from "../../../common/shared/ConfirmPayment";
import jsPDF from "jspdf";
import toast from "react-hot-toast";
import ReactToPrint from "react-to-print";

function ChamberInvoice() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [type, setType] = useState("");
  const [openInvoice, setOpenInvoice] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  const currentDate = new Date().getFullYear();

  const handleOpen = () => setOpen(!open);
  const handleConfirm = () => setConfirm(!confirm);
  const handleType = (name) => setType(name);

  const chamberData = location.state?.formData.data;

  const handleManual = (e) => {
    e.preventDefault();
    const formData = {
      amount: 0,
      year: chamberData.exp_date
        ? new Date(chamberData.exp_date).getFullYear()
        : currentDate,
      chamberId: chamberData.id,
    };

    setLoading(true);
    dispatch(manualChamber(formData)).finally(() => {
      setLoading(false);
      setOpen(true);
    });
  };

  const serviceCharge = chamberData?.exp_date
    ? parseFloat(chamberData?.product?.meta?.renewal * 0.02).toFixed(2)
    : parseFloat(chamberData?.product?.meta?.application * 0.02).toFixed(2);

  const manualCharge = chamberData?.exp_date
    ? parseFloat(chamberData?.product?.meta?.renewal * 0.03).toFixed(2)
    : parseFloat(chamberData?.product?.meta?.application * 0.03).toFixed(2);

  // const handleGeneratePDF = () => {
  //   const content = document.getElementById("content-to-pdf");
  //   const doc = new jsPDF("p", "pt", "a4");

  //   doc.html(content, {
  //     callback: function (doc) {
  //       doc.autoPrint();
  //       window.open(doc.output("bloburl"), "_blank");
  //       setOpen(false);
  //     },
  //     margin: [10, 10, 10, 10],
  //     html2canvas: {
  //       scale: 0.62,
  //       logging: false,
  //       useCORS: true,
  //     },
  //     x: 10,
  //     y: 10,
  //     width: 180,
  //     windowWidth: 900,
  //   });
  // };

  let cardBodyRef = useRef();

  const handleOpenInvoice = () => setOpenInvoice(!openInvoice);

  return (
    <div className="my-8 max-w-[1200px] m-auto px-4">
      <SectionTitle title="Chamber Invoice" description="" children={[]} />

      <Typography className="text-center mb-4" variant="h4">
        Please select invoice type to generate invoice and proceed to make
        payment
      </Typography>

      <div className="flex items-center justify-center gap-3 mb-8">
        <Button
          color="green"
          onClick={() => handleType("manual")}
          fullWidth
          size="lg"
          className="text-sm sm:text-lg md:text-2xl"
        >
          Manual Payment Invoice
        </Button>
        <Button
          color="light-blue"
          onClick={() => handleType("electronic")}
          fullWidth
          size="lg"
          className="text-base sm:text-lg md:text-2xl"
        >
          E-Payment Invoice
        </Button>
      </div>

      {/* <div className="flex items-center justify-center gap-3 mb-8">
        <div>
          <Button onClick={handleOpenInvoice}>Print Invoice</Button>
        </div>

        <ChamberPayment data={chamberData} />

        <Button color="amber" onClick={handleConfirm}>
          {loading ? <Spinner color="white" /> : "Manual Pay"}
        </Button>

       
      </div> */}

      <div className="flex flex-col items-center justify-center">
        <Typography variant="h5" color="red" className="mb-4">
          Electronic Payment attracts a payment charge of 2% (GH¢
          {serviceCharge})
        </Typography>
        <Typography variant="h5" color="red">
          Manual Payment attracts a payment charge of 3% (GH¢
          {manualCharge})
        </Typography>
      </div>

      {type && (
        <>
          <div className="flex items-center justify-center gap-3 mb-8">
            <ReactToPrint
              trigger={() => <Button>Print Invoice</Button>}
              content={() => cardBodyRef}
              removeAfterPrint={false}
            />

            {type === "electronic" ? (
              <ChamberPayment data={chamberData} />
            ) : (
              <Button color="amber" onClick={handleConfirm}>
                {loading ? <Spinner color="white" /> : "Process Payment"}
              </Button>
            )}
          </div>

          <Card>
            <CardBody ref={(el) => (cardBodyRef = el)}>
              <img src={logo} alt="logo" className="h-20" />

              <div className="mt-5 pl-3 flex justify-between">
                <div>
                  <Typography variant="h5">Ghana Bar Association</Typography>
                  <Typography variant="paragraph">
                    H/NO. 5, 2nd Ave. State House Road,
                  </Typography>
                  <Typography variant="paragraph">
                    South-East Ridge, Adjacent to
                  </Typography>
                  <Typography variant="paragraph">
                    Ghana International Press Centre,
                  </Typography>
                  <Typography variant="paragraph">Accra</Typography>
                </div>

                <div>
                  <Typography variant="h5">
                    Billed to: {chamberData?.name}
                  </Typography>
                  <Typography variant="paragraph">
                    Email Address: {chamberData?.email}
                  </Typography>
                  <Typography variant="paragraph">
                    Phone #: {chamberData?.telephone}
                  </Typography>

                  <div className="mt-20">
                    <Typography variant="h6" color="gray">
                      Invoice Date
                    </Typography>
                    <Typography variant="h4">
                      {moment().format("MMMM Do, YYYY")}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className="mt-24">
                <table className="w-full min-w-max table-auto text-left">
                  <thead>
                    <tr>
                      <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          Description
                        </Typography>
                      </th>
                      <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          Qty
                        </Typography>
                      </th>
                      <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          Amount
                        </Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {chamberData?.product?.name} -{" "}
                          {chamberData?.exp_date ? "Renewal" : "Application"}
                        </Typography>
                      </td>

                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          1
                        </Typography>
                      </td>

                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {chamberData?.exp_date
                            ? chamberData?.product?.meta?.renewal
                            : chamberData?.product?.meta?.application}
                        </Typography>
                      </td>
                    </tr>

                    {type && (
                      <tr>
                        <td className="p-4 border-b border-blue-gray-50">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {type === "electronic"
                              ? "E-Payment"
                              : "Manual Payment"}{" "}
                            Processing Charges
                          </Typography>
                        </td>

                        <td className="p-4 border-b border-blue-gray-50">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            1
                          </Typography>
                        </td>

                        <td className="p-4 border-b border-blue-gray-50">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {type === "electronic"
                              ? chamberData?.exp_date
                                ? parseInt(
                                    chamberData?.product?.meta?.renewal
                                  ) * 0.02
                                : parseInt(
                                    chamberData?.product?.meta?.application
                                  ) * 0.02
                              : chamberData?.exp_date
                              ? parseInt(chamberData?.product?.meta?.renewal) *
                                0.03
                              : parseInt(
                                  chamberData?.product?.meta?.application
                                ) * 0.03}
                          </Typography>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-semibold"
                        >
                          Total
                        </Typography>
                      </td>

                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          GH¢
                        </Typography>
                      </td>

                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-semibold"
                        >
                          {chamberData?.exp_date
                            ? type === "electronic"
                              ? parseInt(chamberData?.product?.meta?.renewal) *
                                1.02
                              : parseInt(chamberData?.product?.meta?.renewal) *
                                1.03
                            : type === "electronic"
                            ? parseInt(
                                chamberData?.product?.meta?.application
                              ) * 1.02
                            : parseInt(
                                chamberData?.product?.meta?.application
                              ) * 1.03}
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mt-40">
                <Typography variant="h4">Thank you!</Typography>
                <Typography variant="paragraph">
                  If you encounter any issue related to the invoice you can
                  contact us at:
                </Typography>

                <Typography variant="paragraph">
                  email:{" "}
                  <span className="font-semibold">info@ghanabar.org</span>
                </Typography>
              </div>
            </CardBody>
          </Card>

          {confirm && (
            <ConfirmPayment
              open={confirm}
              setOpen={setConfirm}
              title="Manual"
              amount={
                chamberData?.exp_date
                  ? chamberData?.product.meta.renewal
                  : chamberData?.product.meta.application
              }
              loading={loading}
              handleConfirm={(e) => handleManual(e)}
              handleClose={handleConfirm}
            />
          )}
        </>
      )}

      {open && (
        <Dialog open={open} handleOpen={handleOpen}>
          <DialogHeader>
            <Typography variant="h5" color="blue-gray">
              Your manual payment would be processed at the Bar Secretariat
            </Typography>
          </DialogHeader>
          <DialogFooter>
            <Button variant="text" color="blue-gray" onClick={handleOpen}>
              Close
            </Button>
          </DialogFooter>
        </Dialog>
      )}

      {openInvoice && (
        <Dialog open={openInvoice} size="sm" handler={handleOpenInvoice}>
          <DialogHeader>Inovice Type</DialogHeader>
          <DialogBody>
            <Typography color="black" variant="lead" className="mb-2">
              Please select invoice type.
            </Typography>
          </DialogBody>
          <DialogFooter className="flex justify-between">
            <Button
              // onClick={() => handleGeneratePDF("electronic")}
              color="pink"
            >
              Electronic Payment Invoice
            </Button>

            <Button color="amber">Manual Payment Invoice</Button>
          </DialogFooter>
        </Dialog>
      )}
    </div>
  );
}

export default ChamberInvoice;
