import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Option,
  Select,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";
import DetailItem from "../../../common/shared/DetailItem";
import moment from "moment";
import TrainingTag from "./TrainingTag";
import TrainingPayment from "./TrainingPayment";
import TrainingReceiptButton from "./TrainingReceiptButton";
import TrainingGallery from "./TrainingGallery";

function TrainingList({ row }) {
  const [showModal, setShowModal] = useState(false);
  const [subType, setSubType] = useState("");

  const modalHandler = () => setShowModal(!showModal);

  return (
    row &&
    row.map((data) => (
      <div key={data.id} className="card-wrapper">
        {!data.isPaid && (
          <Button className="mb-2" color="pink" onClick={modalHandler}>
            Make Payment
          </Button>
        )}
        <Card>
          <CardBody>
            <TrainingTag data={data.isPaid} />

            <Typography variant="h5" color="blue-gray" className="mb-4">
              {data.name} ({data?.meta?.attendance} Attendance)
            </Typography>

            {data?.meta?.theme && (
              <DetailItem title="Theme" item={data?.meta?.theme} />
            )}

            {data?.meta?.speaker && (
              <DetailItem title="Facilitator" item={data?.meta?.speaker} />
            )}

            <div className="flex flex-col sm:flex-row  justify-between">
              <DetailItem
                title="Accra Details"
                item={
                  <div className="flex flex-col">
                    <div
                      className={
                        moment().isAfter(data.meta.venue1.date)
                          ? "line-through text-gray-500"
                          : ""
                      }
                    >
                      Venue: {data.meta.venue1.venue}
                    </div>
                    <div
                      className={
                        moment().isAfter(data.meta.venue1.date)
                          ? "line-through text-gray-500"
                          : ""
                      }
                    >
                      Date: {moment(data.meta.venue1.date).format("LL")}
                    </div>
                    <div
                      className={
                        moment().isAfter(data.meta.venue1.date)
                          ? "line-through text-gray-500"
                          : ""
                      }
                    >
                      Time: {data.meta.venue1.time}
                    </div>
                  </div>
                }
              />

              <DetailItem
                title="Kumasi Details"
                item={
                  <div className="flex flex-col">
                    <div
                      className={
                        moment().isAfter(data.meta.venue2.date)
                          ? "line-through text-gray-500"
                          : ""
                      }
                    >
                      Venue: {data.meta.venue2.venue}
                    </div>
                    <div
                      className={
                        moment().isAfter(data.meta.venue2.date)
                          ? "line-through text-gray-500"
                          : ""
                      }
                    >
                      Date: {moment(data.meta.venue2.date).format("LL")}
                    </div>
                    <div
                      className={
                        moment().isAfter(data.meta.venue2.date)
                          ? "line-through text-gray-500"
                          : ""
                      }
                    >
                      Time: {data.meta.venue2.time}
                    </div>
                  </div>
                }
              />

              <DetailItem
                title="Takoradi Details"
                item={
                  <div className="flex flex-col">
                    <div>Venue: {data.meta.venue3.venue}</div>
                    <div>
                      Date: {moment(data.meta.venue3.date).format("LL")}
                    </div>
                    <div>Time: {data.meta.venue3.time}</div>
                  </div>
                }
              />
            </div>

            <DetailItem
              title="Info Flyer"
              item={
                <>
                  <TrainingGallery />
                </>
              }
            />

            <DetailItem title="Amount" item={`GH¢${data.amount.toFixed(2)}`} />
          </CardBody>
          <CardFooter>
            {data.isPaid ? (
              <div className="flex flex-col gap-4 md:flex-row md:items-center">
                <TrainingReceiptButton
                  link={`/home/training/receipt/${data.receipt?.id}`}
                />
              </div>
            ) : (
              <Button className="mb-2" color="pink" onClick={modalHandler}>
                Make Payment
              </Button>
            )}
          </CardFooter>
        </Card>

        {showModal && (
          <Dialog open={showModal} handler={modalHandler} size="xs">
            <DialogHeader>Select City</DialogHeader>
            <DialogBody>
              <Select
                variant="outlined"
                label="Select City"
                value={subType}
                onChange={(val) => setSubType(val)}
              >
                {moment().isBefore(data.meta.venue1.date) && (
                  <Option value="Accra">Accra</Option>
                )}

                {moment().isBefore(data.meta.venue2.date) && (
                  <Option value="Kumasi">Kumasi</Option>
                )}

                <Option value="Takoradi">Takoradi</Option>
              </Select>
            </DialogBody>
            <DialogFooter>
              <Button
                variant="text"
                color="close"
                onClick={modalHandler}
                className="mr-1"
              >
                Close
              </Button>
              <TrainingPayment
                data={{ ...data, sub_type: subType }}
                disable={!subType}
              />
            </DialogFooter>
          </Dialog>
        )}
      </div>
    ))
  );
}

export default TrainingList;
