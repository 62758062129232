import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUserTrainingAction } from "../../redux/actions/training_action";
import SectionTitle from "../../common/shared/SectionTitle";
import Loader from "../../common/loader/Loader";
import NoContent from "../../common/shared/NoContent";
import TrainingList from "./components/TrainingList";

const link = [{ name: "Training", link: "" }];

function Training() {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.training);

  useEffect(() => {
    dispatch(getUserTrainingAction()).finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <>
      <SectionTitle title="AI Training" children={link} description="" />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        {loading ? (
          <Loader />
        ) : data.training?.length === 0 ? (
          <NoContent message="No Training Available Yet." />
        ) : (
          <TrainingList row={data?.training} />
        )}
      </div>
    </>
  );
}

export default Training;
